import jwt_decode from 'jwt-decode';
import API from 'base/auth/constants';
import { authSliceActions } from 'base/auth/slice';
import { coreSliceActions } from 'base/core/slice';
import { ENVIRONMENT } from 'config';
import {
  getAuthToken, getRefreshToken, setAuthToken, setRefreshToken,
} from 'helpers/token';
import { IRestApiResponse } from 'interfaces';

let store:any;
export const injectStore = (_store:any) => {
  store = _store;
};
let adminEntId:string;

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object} The parsed JSON from the request
 */
const parseJSON = async (response: Response) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = async (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const res = await parseJSON(response);

  if (response.status === 401) {
    if (res?.errors?.userLoggedOut) {
      window.location.reload();
    }
    store.dispatch(coreSliceActions.setMessage({ message: res?.message || 'Unkown error', success: false }));
    store.dispatch(authSliceActions.signOut());
  } else if (response.status === 423) {
    store.dispatch(authSliceActions.signOut());
    store.dispatch(coreSliceActions.setMessage({ message: res?.message || 'Unkown error', success: false }));
  } else {
    store.dispatch(coreSliceActions.setMessage({ message: res?.message || 'Unkown error', success: false }));
    throw new Error(res?.message || 'Unkown error');
  }

  const error = new ResponseError(response);
  error.response = response;
  throw error;
};

/**
 * use the refresh token to get the new tokens
 */
const refreshToken = async () => {
  const fetchResponse = await fetch(`${ENVIRONMENT.BACKEND_API}${API.POST_REFRESH_TOKEN.path}`, {
    body: JSON.stringify({
      token: getRefreshToken(),
      email: (jwt_decode(getAuthToken() as string) as any).username,
    }),
    method: API.POST_REFRESH_TOKEN.method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (fetchResponse.status === 401) {
    store.dispatch(authSliceActions.signOut());
    return;
  }
  const res = (await fetchResponse.json()) as IRestApiResponse;
  setAuthToken(res.data.idToken.jwtToken);
  setRefreshToken(res.data.refreshToken.token);
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  _metadata: any,
  data: any,
  isSecure: boolean = true,
  isFormData: boolean = false,
  url: string = ENVIRONMENT.BACKEND_API,
  entId:string = '',
): Promise<any> {
  const metadata = { ..._metadata };
  const pathTokens = metadata.path.split(':');
  if (metadata.path.indexOf(':') !== 0) {
    pathTokens.shift();
  }
  pathTokens.forEach((token: string) => {
    metadata.path = metadata.path.replace(`:${token}`, `${data[token]}`);
  });

  const backEndUrl = `${url}${metadata.path}`;
  const formData = new FormData();
  if (isFormData) {
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
  }

  // check if the token is expires
  if (getAuthToken()) {
    if ((jwt_decode(getAuthToken() as string) as any).exp < (Date.now() / 1000)) {
      await refreshToken();
    }
  }
  const authState = store.getState()['feature/base/auth'];

  if (store && authState) {
    adminEntId = authState.entId;
  }
  const options: RequestInit = {
    method: metadata.method,
    mode: 'cors', // no-cors, cors, same-origin
    cache: 'no-cache',
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      ...(isSecure && {
        Authorization: `Bearer ${getAuthToken() || ''}`,
      }),
      ...(!isFormData && {
        'Content-Type': 'application/json',
      }),
      'X-ENT-ID': `${adminEntId}`,
      ...(entId !== '' && {
        'X-ENT-ID': entId,
      }),
      'x-platform-id': 'ent-hub',
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    ...(['POST', 'PUT', 'PATCH', 'DELETE'].includes(metadata.method) && {
      body: (isFormData ? formData : JSON.stringify(data)),
    }),
  };

  let res: any;

  try {
    const fetchResponse = await fetch(backEndUrl, options);
    const response = await checkStatus(fetchResponse);
    res = await parseJSON(response);
  } catch (error) {
    if (error instanceof TypeError) {
      const host = window.location.host.replace('ent-admin.', '');
      const challengeUrl = `https://challenge.${host}`;

      const redirectUrl = new URL(challengeUrl);
      redirectUrl.searchParams.set('callbackUrl', window.location.pathname);
      redirectUrl.searchParams.set('platformId', 'admin');
      redirectUrl.searchParams.set('subdomain', 'ent-admin');

      window.location.href = redirectUrl.toString();
    } else {
      throw error;
    }
  }
  return res;
}
