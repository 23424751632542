/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { AnyAction, PreloadedState, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'react';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import rootSaga from 'root-saga';
import { authSlice } from 'base/auth/slice';
import { createEntAccSlice } from 'pages/dashboard/components/ent-account/components/create-ent-account/slice';
import { viewEntAccSlice } from 'pages/dashboard/components/ent-account/components/ent-accounts-view/slice';
import { editEntAccSlice } from 'pages/dashboard/components/ent-account/components/edit-account/slice';
import { viewExpertSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/view-experts/slice';
import { createExpertSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/create-expert/slice';
import { createAdminSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/components/create-admin/slice';
import { editAdminSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/components/edit-admin/slice';
import { editExpertSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/slice';
import { viewAdminSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/components/view-admins/slice';
import { viewUserSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/users/components/view-users/slice';
import { viewGroupSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/components/view-groups/slice';
import { viewArticleSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/articles/components/view-articles/slice';
import { createArticleSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/articles/components/create-article/slice';
import { createSessionSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/sessions/components/create-session/slice';
import { OnboardEntAccSlice } from 'pages/dashboard/components/ent-account/components/onboard-ent-account/slice';
import { viewSessionsSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/sessions/components/view-sessions/slice';
import { viewContentSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/content/slice';
import { viewSessionSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/sessions/components/view-session/slice';
import { newsFeedSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/news-feed/slice';
import { viewReviewsSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/reviews/slice';
import { viewFeedbackSlice } from 'pages/dashboard/components/feedback/slice';
import { editGroupSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/components/edit-groups/slice';
import { coreSlice } from 'base/core/slice';
import { overviewSlice } from 'pages/dashboard/components/overview/slice';
import { viewFormsSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/forms/slice';
import { createUserSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/users/components/create-user/slice';
import { createGroupSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/components/create-group/slice';
import { editUserSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/users/components/edit-user/slice';
import { RootState } from 'interfaces';
import { viewGiftCardSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/gift-cards/components/view-gift-cards/slice';
import { createGiftCardSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/gift-cards/components/create-gift-card/slice';
import { viewGiftCardUsersSlice } from 'pages/dashboard/components/ent-account/components/edit-account/components/gift-cards/components/view-users/slice';

export const sagaMiddleware = createSagaMiddleware();
const createSessionPersistConfig = {
  key: 'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/create-session',
  storage,
  whitelist: ['courseOnlySessionMeta'],
};

const reducers = combineReducers({
  'feature/base/auth': authSlice.reducer,
  'feature/ent-acc/onboard-ent-acc': OnboardEntAccSlice.reducer,
  'feature/base/core': coreSlice.reducer,
  'feature/overview': overviewSlice.reducer,
  'feature/ent-acc/create-ent-acc': createEntAccSlice.reducer,
  'feature/ent-acc/view-ent-acc': viewEntAccSlice.reducer,
  'feature/ent-acc/edit-ent-acc': editEntAccSlice.reducer,
  'feature/ent-acc/edit-ent-acc/expert/view-expert': viewExpertSlice.reducer,
  'feature/ent-acc/edit-ent-acc/expert/create-expert': createExpertSlice.reducer,
  'feature/ent-acc/edit-ent-acc/expert/edit-expert': editExpertSlice.reducer,
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/create-session':
  persistReducer(createSessionPersistConfig, createSessionSlice.reducer),
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/view-session': viewSessionSlice.reducer,
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/view-sessions': viewSessionsSlice.reducer,
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/content': viewContentSlice.reducer,
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/news-feed': newsFeedSlice.reducer,
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/view-reviews': viewReviewsSlice.reducer,
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/view-forms': viewFormsSlice.reducer,
  'feature/ent-acc/edit-ent-acc/admin/view-admin': viewAdminSlice.reducer,
  'feature/ent-acc/edit-ent-acc/admin/create-admin': createAdminSlice.reducer,
  'feature/ent-acc/edit-ent-acc/admin/edit-admin': editAdminSlice.reducer,
  'feature/ent-acc/edit-ent-acc/user/view-user': viewUserSlice.reducer,
  'feature/ent-acc/edit-ent-acc/user/edit-user': editUserSlice.reducer,
  'feature/ent-acc/edit-ent-acc/group/create-group': createGroupSlice.reducer,
  'feature/ent-acc/edit-ent-acc/group/view-group': viewGroupSlice.reducer,
  'feature/ent-acc/edit-ent-acc/group/edit-group': editGroupSlice.reducer,
  'feature/ent-acc/edit-ent-acc/article/view-article': viewArticleSlice.reducer,
  'feature/ent-acc/edit-ent-acc/article/create-article': createArticleSlice.reducer,
  'feature/ent-acc/edit-ent-acc/admin/create-user': createUserSlice.reducer,
  'feature/ent-acc/edit-ent-acc/gift-card/view-gift-card': viewGiftCardSlice.reducer,
  'feature/ent-acc/edit-ent-acc/gift-card/create-gift-card': createGiftCardSlice.reducer,
  'feature/ent-acc/edit-ent-acc/gift-cards/view-users': viewGiftCardUsersSlice.reducer,
  'feature/ent-acc/view-feedback': viewFeedbackSlice.reducer,
});
const blacklist = ['feature/forgot-password', 'feature/reset-password', 'feature/reset-temp-password', 'feature/ent-acc/onboard-ent-acc',
  'feature/overview', 'feature/ent-acc/view-ent-acc', 'feature/ent-acc/edit-ent-acc', 'feature/ent-acc/edit-ent-acc/expert/view-expert', 'feature/ent-acc/edit-ent-acc/expert/edit-expert',
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/view-session',
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/view-sessions', 'feature/ent-acc/edit-ent-acc/expert/edit-expert/content', 'feature/ent-acc/edit-ent-acc/expert/edit-expert/news-feed',
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/view-reviews', 'feature/ent-acc/edit-ent-acc/expert/edit-expert/view-forms', 'feature/ent-acc/edit-ent-acc/admin/view-admin', 'feature/ent-acc/edit-ent-acc/admin/create-admin', 'feature/ent-acc/edit-ent-acc/admin/edit-admin',
  'feature/ent-acc/edit-ent-acc/user/view-user', 'feature/ent-acc/edit-ent-acc/group/view-group', 'feature/ent-acc/edit-ent-acc/group/edit-group', 'feature/ent-acc/edit-ent-acc/article/view-article',
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/session/view-sessions', 'feature/ent-acc/edit-ent-acc/expert/edit-expert/content', 'feature/ent-acc/edit-ent-acc/expert/edit-expert/newsFeed',
  'feature/ent-acc/edit-ent-acc/expert/edit-expert/view-reviews', 'feature/ent-acc/edit-ent-acc/admin/view-admin', 'feature/ent-acc/edit-ent-acc/admin/create-admin', 'feature/ent-acc/edit-ent-acc/admin/edit-admin',
  'feature/ent-acc/edit-ent-acc/user/view-user', 'feature/ent-acc/edit-ent-acc/user/edit-user', 'feature/ent-acc/edit-ent-acc/group/view-group', 'feature/ent-acc/edit-ent-acc/group/create-group', 'feature/ent-acc/edit-ent-acc/group/edit-group',
  'feature/ent-acc/view-feedback', 'feature/ent-acc/edit-ent-acc/gift-card/view-gift-card', 'feature/ent-acc/edit-ent-acc/gift-card/create-gift-card', 'feature/ent-acc/edit-ent-acc/gift-cards/view-users',

];
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [...blacklist],
};
const persistedReducer = persistReducer(persistConfig, reducers as Reducer<any, AnyAction>);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);

/* this function is used to mock store in tests */
export const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
  reducer: persistedReducer,
  middleware: [],
  preloadedState,
});

export default store;
