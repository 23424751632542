import React from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  Grid, Typography,
} from '@mui/material';
import {
  ErrorMessage,
  FileUploader,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimaryTextField,
} from 'components';

import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { IBadge } from '../../../../../interface';

interface BadgeModalFormProps {
  title:string,
  onSubmit: SubmitHandler<any>;
  closeModal: () => void;
  loading:boolean;
}

const BadgeModalForm = ({
  title,
  onSubmit,
  closeModal, loading,
}: BadgeModalFormProps) => {
  const {
    control, handleSubmit,
    setValue, getValues, clearErrors, setError,
    formState: { errors },
  } = useFormContext<IBadge>();

  const handleUrl = (res:UploadResponse) => {
    clearErrors('badgeImgUrl');
    setValue('badgeImgUrl', res.url);
    if (res.statusCode !== 201) {
      setError('badgeImgUrl', { type: 'custom', message: res.message });
    }
  };
  const handleUrlRemove = () => {
    setValue('badgeImgUrl', '');
  };
  const handleForm = (event:any) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={handleForm}>
      <Typography variant="h4" className="form-item">
        {title}
      </Typography>
      <div className="form-item">
        <Typography variant="h4">Course badge</Typography>
        <FileUploader
          handleFile={handleUrl}
          handleRemove={handleUrlRemove}
          currentContentUrl={getValues('badgeImgUrl')}
          scenario={UploadScenarios.sessionBanner}
          currentMediaType="image"
        />
        <ErrorMessage>{errors?.badgeImgUrl?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="badgeTitle"
          render={({ field }) => <PrimaryTextField {...field} label="Badge title" />}
        />
        <ErrorMessage>{errors?.badgeTitle?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="badgeDescription"
          render={({ field }) => <PrimaryTextField {...field} multiline label="Badge description" />}
        />
        <ErrorMessage>{errors?.badgeDescription?.message}</ErrorMessage>
      </div>

      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLiteButton fullWidth onClick={closeModal}>Cancel</PrimaryLiteButton>
        </Grid>
        <Grid item xs>
          <PrimaryLoadingButton loading={loading} fullWidth type="submit">
            Add & continue
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </form>
  );
};

export default BadgeModalForm;
