const ROUTE = {
  LOGIN: '/login/*',
  SIGNUP: '/sign-up/*',
  RESET_PASSWORD: '/reset-password/*',
  RESET_TEMP_PASSWORD: '/reset-temp-password/*',
  FORGOT_PASSWORD: '/forgot-password/*',
  DASHBOARD: '/*',
  ENT_ACCOUNT: '/ent-account/*',
  CREATE_ENT_ACCOUNT: '/ent-account/create-ent-account/*',
  ENT_EDIT: '/ent-account/edit/:id/*',
  CREATE_COURSE_ONLY_SESSION: '/ent-account/edit/:id/experts/edit-expert/:expertId/sessions/create/course-only-session/*',
  ARTICLE_PREVIEW: '/ent-account/edit/:id/articles/preview',
  FEEDBACK: '/feedback/*',
  ONBOARD_ENT_ACCOUNT: '/ent-account/onboard-ent-account/:id/*',
  HEALTH: '/health',
  UNAUTHORIZED: '/unauthorized',
};
export default ROUTE;
