/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import {
  Controller, SubmitHandler, useFormContext, useFieldArray,
} from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { FormControlLabel, Grid, RadioGroup } from '@mui/material';
import {
  ColorPicker,
  ErrorMessage,
  FileUploader,
  Label,
  PrimaryLoadingButton,
  PrimaryLoadingOutlinedButton,
  PrimaryRadio,
  SponsorLogoUploader,
} from 'components';
import headerUrl from 'assets/images/slocoach.com-enterprise-hero-template-01.png';
import headerUrl2 from 'assets/images/slocoach.com-enterprise-hero-template-02.png';
import headerUrl3 from 'assets/images/slocoach.com-enterprise-hero-template-03.png';
import handleContentDataUpload, { handleContentDataDelete } from 'utils/file-uploader';
import { UploadResponse, UploadScenarios } from 'interfaces/file-uploader';
import { urlRegMatch } from 'helpers/validations';
import FontOptions from 'config/font-options';
import { IOnboardSitePreferencesModalForm } from '../../../../../interface';
import { selectSitePreferenceForm } from '../../../../../selector';
import OnboardSponsorLogoModal from '../../upload-sponsor-logo-model/upload-sponsor-logo-modal-view';

interface OnboardSitePreferencesModalFormProps {
  onSubmit: SubmitHandler<any>;
  loading: boolean;
}

const OnboardSitePreferencesModalForm = ({ onSubmit }: OnboardSitePreferencesModalFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues, setError, clearErrors,
  } = useFormContext<IOnboardSitePreferencesModalForm>();
  const [isFontDefault, setIsFontDefault] = React.useState(
    useSelector(selectSitePreferenceForm).isFontDefault,
  );
  const [headerFontName, setHeaderFontName] = React.useState(getValues('headerFont.name'));
  const [bodyFontName, setBodyFontName] = React.useState(getValues('bodyFont.name'));
  const [headerFontLoading, setHeaderFontLoading] = React.useState(false);
  const [bodyFontLoading, setBodyFontLoading] = React.useState(false);
  const headerFontFileInput:any = React.useRef(null);
  const bodyFontFileInput:any = React.useRef(null);
  const [sponsorContentUrls, setSponsorContentUrls] = useState<{ url:string, action:string }[]>([]);
  const sponsorContentFieldArray = useFieldArray({ name: 'sponsorContent', control });
  const promotionalContent = getValues('promotionalContent');

  let j = 0;

  // handling fonts

  const handleHeaderFontClick = () => {
    headerFontFileInput.current.click();
  };
  const handleBodyFontClick = () => {
    bodyFontFileInput.current.click();
  };
  const handleFontFamilyChange = (event: any) => {
    setIsFontDefault(event.target.value);
    setValue('isFontDefault', event.target.value);
  };

  const handleHeaderFont = async (event: any) => {
    setHeaderFontLoading(true);
    const res = await handleContentDataUpload(
      event,
      getValues('headerFont.url'),
      UploadScenarios.entFontUpload,
    );
    setValue('headerFont', res);
    setHeaderFontName(res.name);
    setHeaderFontLoading(false);
  };

  const handleBodyFont = async (event: any) => {
    setBodyFontLoading(true);
    const res = await handleContentDataUpload(
      event,
      getValues('bodyFont.url'),
      UploadScenarios.entFontUpload,
    );
    setValue('bodyFont', res);
    setBodyFontName(res.name);
    setBodyFontLoading(false);
  };

  // handling sponsor logos

  const addSponsorContentUrl = (value: string) => {
    sponsorContentUrls.push({ url: value, action: '' });
    setSponsorContentUrls(sponsorContentUrls);
  };

  const addSponsorLogo = (url:string, contentUrl:string) => {
    sponsorContentFieldArray.append({ url, contentUrl });
    addSponsorContentUrl(contentUrl);
  };
  const removeSponsorLogo = (index: number) => {
    sponsorContentFieldArray.remove(index);
  };

  const handleSponsorLogoInputChange = (index: number, input: string) => {
    clearErrors(`sponsorContent.${index}.contentUrl`);
    setValue(`sponsorContent.${index}.contentUrl`, input);
    if (!(urlRegMatch).test(input)) {
      setError(`sponsorContent.${index}.contentUrl`, { type: 'custom', message: 'Invalid url' });
    }
  };
  const handleSponsorLogoUpload = (index: number, res: UploadResponse) => {
    clearErrors(`sponsorContent.${index}.url`);
    setValue(`sponsorContent.${index}.url`, res.url);
    if (res.statusCode !== 201) {
      setError(`sponsorContent.${index}.url`, { type: 'custom', message: res.message });
    }
  };

  // handling promotional content

  const handleUploadPromotionalContent = (res: UploadResponse) => {
    clearErrors('promotionalContent.url');
    setValue('promotionalContent', { url: res.url, fileCategory: res.category });
    if (res.statusCode !== 201) {
      setError('promotionalContent.url', { type: 'custom', message: res.message });
    }
  };

  const handleRemoveImage = async () => {
    await handleContentDataDelete(promotionalContent.url, UploadScenarios.entPromotional);
    setValue('promotionalContent.url', '');
  };

  const removeSponsoLogoImage = (index: number) => {
    sponsorContentFieldArray.remove(index);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item">Website fonts</h3>
      <div className="form-item">
        <Controller
          control={control}
          name="isFontDefault"
          render={({ field }) => (
            <RadioGroup {...field} value={isFontDefault} onChange={handleFontFamilyChange}>
              <Label>Font family</Label>
              <Grid container justifyContent="start-flex">
                <Grid item>
                  <FormControlLabel
                    value={FontOptions.default}
                    control={<PrimaryRadio />}
                    label="Use default fonts"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={FontOptions.custom}
                    control={<PrimaryRadio />}
                    label="Upload custom fonts"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
        <ErrorMessage>{errors?.isFontDefault?.message}</ErrorMessage>
      </div>
      {(isFontDefault === FontOptions.custom) && (
        <>
          <div className="form-item">
            <div style={{ textAlign: 'left' }}>
              <h4>Headline font</h4>
              <Label>
                This font will be used on all the titles and subtitles on your website. We recommend
                using a bold version or a variable font that will represent your brand and styling.
                Use the fonts in WOFF or WOFF2 format.
              </Label>
            </div>
            {headerFontName === '' ? (
              <PrimaryLoadingOutlinedButton
                sx={{ padding: '0.5rem 0' }}
                onClick={handleHeaderFontClick}
                loading={headerFontLoading}
              >
                <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
                Upload font
              </PrimaryLoadingOutlinedButton>
            ) : (
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={9}>
                  <h4>{headerFontName}</h4>
                </Grid>
                <Grid item xs>
                  <PrimaryLoadingOutlinedButton
                    sx={{ padding: '0.5rem 0' }}
                    onClick={handleHeaderFontClick}
                    loading={headerFontLoading}
                  >
                    <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
                    Replace font
                  </PrimaryLoadingOutlinedButton>
                </Grid>
              </Grid>
            )}
            <input
              type="file"
              accept=".woff,.woff2"
              ref={headerFontFileInput}
              onChange={handleHeaderFont}
              style={{ display: 'none' }}
            />
            <ErrorMessage>{errors?.headerFont?.name?.message}</ErrorMessage>
            <ErrorMessage>{errors?.headerFont?.url?.message}</ErrorMessage>
          </div>
          <div className="form-item">
            <div style={{ textAlign: 'left' }}>
              <h4>Body font</h4>
              <Label>
                This font will be used on all the body, copy, labels, descriptions and buttons. We
                recommend uploading a variable font or regular and semibold versions of the font.
                Use the fonts in WOFF or WOFF2 format.
              </Label>
            </div>
            {bodyFontName === '' ? (
              <PrimaryLoadingOutlinedButton
                sx={{ padding: '0.5rem 0' }}
                onClick={handleBodyFontClick}
                loading={bodyFontLoading}
              >
                <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
                Upload font
              </PrimaryLoadingOutlinedButton>
            ) : (
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={9}>
                  <h4>{bodyFontName}</h4>
                </Grid>
                <Grid item xs>
                  <PrimaryLoadingOutlinedButton
                    sx={{ padding: '0.5rem 0' }}
                    onClick={handleBodyFontClick}
                    loading={bodyFontLoading}
                  >
                    <AddIcon sx={{ margin: '0 0.5rem 0 0' }} />
                    Replace font
                  </PrimaryLoadingOutlinedButton>
                </Grid>
              </Grid>
            )}

            <input
              type="file"
              accept=".woff,.woff2"
              ref={bodyFontFileInput}
              onChange={handleBodyFont}
              style={{ display: 'none' }}
            />
            <ErrorMessage>{errors?.bodyFont?.name?.message}</ErrorMessage>
            <ErrorMessage>{errors?.bodyFont?.url?.message}</ErrorMessage>
          </div>
        </>
      )}

      <h3 className="form-item">Website Colors</h3>
      <div className="form-item">
        <div style={{ textAlign: 'left' }}>
          <h4>Primary Color</h4>
          <Label>
            This color will be used on all the buttons and clickable links of the website. We
            recommend using your brand's primary color for this.
          </Label>
        </div>
        <Controller
          control={control}
          name="colors.primaryColor"
          render={({ field }) => <ColorPicker {...field} label="Primary Color" />}
        />
        <ErrorMessage>{errors?.colors?.primaryColor?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <div style={{ textAlign: 'left' }}>
          <h4>Secondary Color</h4>
          <Label>
            This color will be used as a secondary color on the website such as important content,
            highlighted items, icons, etc.
          </Label>
        </div>
        <Controller
          control={control}
          name="colors.secondaryColor"
          render={({ field }) => <ColorPicker {...field} label="Secondary Color" />}
        />
        <ErrorMessage>{errors?.colors?.secondaryColor?.message}</ErrorMessage>
      </div>
      <h4 className="form-item">Header Template</h4>
      <div className="form-item">
        <Controller
          control={control}
          name="headerTemplate"
          render={({ field }) => (
            <RadioGroup {...field}>
              <Grid container justifyContent="start-flex">
                <Grid item>
                  <div>
                    <FormControlLabel value="1" control={<PrimaryRadio />} label="Option #1" />
                    <div style={{ margin: '1rem' }}>
                      <img src={headerUrl} alt="header-template-option" />
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div>
                    <FormControlLabel value="2" control={<PrimaryRadio />} label="Option #2" />
                    <div style={{ margin: '1rem' }}>
                      <img src={headerUrl2} alt="header-template-option" />
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div>
                    <FormControlLabel value="3" control={<PrimaryRadio />} label="Option #3" />
                    <div style={{ margin: '1rem' }}>
                      <img src={headerUrl3} alt="header-template-option" />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
        <ErrorMessage>{errors?.headerTemplate?.message}</ErrorMessage>
      </div>
      <h3 className="form-item">Sponsors logo</h3>
      <div className="form-item">
        <div style={{ textAlign: 'left' }}>
          <Label>
            These will be the companies that you work with or your sponsors. We recommend using an
            image with a height of 100 pixels or higher in or PNG format.
          </Label>
        </div>
      </div>
      <div className="form-item">
        {sponsorContentFieldArray.fields.map((item, i) => (
          <div key={item.id}>
            <div className="form-item">
              <SponsorLogoUploader
                index={i}
                label={`sponsor #${(j = i + 1)}`}
                imageLabel="Sponsor Image"
                textFieldLabel="Sponsor Url"
                handleRemove={removeSponsorLogo}
                handleInputChange={handleSponsorLogoInputChange}
                handleFile={handleSponsorLogoUpload}
                currentText={item?.contentUrl}
                currentImageUrl={item.url}
                scenario={UploadScenarios.entSponsor}
                handleRemoveImage={removeSponsoLogoImage}
              />
              <ErrorMessage>
                {errors?.sponsorContent ? errors?.sponsorContent[i]?.url?.message : ''}
              </ErrorMessage>
              <ErrorMessage>
                {errors?.sponsorContent ? errors?.sponsorContent[i]?.contentUrl?.message : ''}
              </ErrorMessage>
            </div>
          </div>
        ))}
      </div>
      <div className="form-item">
        <OnboardSponsorLogoModal index={j + 1} addSponsorLogo={addSponsorLogo} />
      </div>
      <h3 className="form-item">Promotional content</h3>
      <div className="form-item">
        <div style={{ textAlign: 'left' }} className="form-item">
          <Label>
            These images will be displayed in the header section of your homepage. These could be
            any type of promotional content. Required image size is 808x404 pixels in PNG or JPG
            format.
          </Label>
        </div>
        <div className="form-item">
          <FileUploader
            currentContentUrl={promotionalContent?.url}
            handleFile={handleUploadPromotionalContent}
            handleRemove={handleRemoveImage}
            scenario={UploadScenarios.entPromotional}
            currentMediaType={promotionalContent?.fileCategory}
          />
        </div>

        <ErrorMessage>
          {errors ? errors?.promotionalContent?.url?.message : ''}
        </ErrorMessage>
      </div>
      <div className="form-item" />
      <div className="form-item" />
      <div>
        <PrimaryLoadingButton size="large" type="submit" variant="contained">
          Save & continue
        </PrimaryLoadingButton>
      </div>
    </form>
  );
};
export default OnboardSitePreferencesModalForm;
