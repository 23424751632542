import * as React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { SessionType, SessionAudienceTypes } from 'config';
import { selectCreateSession, selectInvitations, selectSessionInformation } from '../../../../selector';
import { ICreateSessionForm } from '../../../../interface';
import { createSessionActions } from '../../../../slice';
import SessionInformationModalForm from '../session-information-form';
import sessionInformationFormValidation from '../../validation/session-information-validation';

interface SessionInformationViewProps {
  editMode:boolean,
  courseOnlySession:boolean
}

export default function SessionInformationView({
  editMode,
  courseOnlySession,
}:SessionInformationViewProps) {
  const dispatch = useDispatch();
  const sessionInformation = useSelector(selectSessionInformation);
  const invitations = useSelector(selectInvitations);
  const { bookedSessions } = useSelector(selectCreateSession);
  const methods = useForm<ICreateSessionForm>({
    defaultValues: {
      ...sessionInformation,
      sessionType: SessionType.liveClass,
      audienceType: courseOnlySession
        ? SessionAudienceTypes.courseOnly : sessionInformation.audienceType,
      bookedSessions,
    },
    resolver: yupResolver(sessionInformationFormValidation),
    context: {
      sessionType: sessionInformation.sessionType,
      invitations,
      editMode,
    },
    shouldFocusError: true,
  });

  const onSubmit:
  SubmitHandler<ICreateSessionForm> = React.useCallback((data) => {
    dispatch(createSessionActions.AddSessionInformation(data));
  }, [dispatch]);

  return (
    <div>
      <FormProvider {...methods}>
        <SessionInformationModalForm
          onSubmit={onSubmit}
          editMode={editMode}
          courseOnlySession={courseOnlySession}
        />
      </FormProvider>
    </div>
  );
}
