import * as React from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  ErrorMessage,
  PrimaryDatePicker,
  PrimaryLoadingButton,
  PrimaryRadio,
  PrimarySelect,
  PrimaryTimePicker,
} from 'components';

import { DaysOfTheWeek, SessionFrequencyTypes, SessionAudienceTypes } from 'config';
import { getOptionsFromEnum } from 'utils/data-utils';
import { IOption } from 'interfaces';
import { CourseOnlySessionStatus, ILiveClassFrequencyForm } from '../../../../interface';
import { selectCourseOnlySessionMeta, selectSessionInformation } from '../../../../selector';

interface SessionFrequencyModalFormProps {
  onSubmit: SubmitHandler<any>;
  loading: boolean;
}

const sessionLimits = {
  'One time': 1,
  'Every Day': 365,
  'Every Week': 52,
  'Every month': 12,
};
// TODO:
// setup the preview component
const SessionFrequencyModalForm = ({ onSubmit, loading }: SessionFrequencyModalFormProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    watch,
  } = useFormContext<ILiveClassFrequencyForm>();

  const daysOfTheWeek = getOptionsFromEnum(DaysOfTheWeek);
  const daysOfTheMonth: IOption[] = [...Array(31)].map((_i: number, index: number) => ({
    id: (index + 1).toString(),
    value: (index + 1).toString(),
    text: (index + 1).toString(),
  }));
  const { numberOfSessions, audienceType } = useSelector(selectSessionInformation);
  const courseOnlySessionStatus = useSelector(selectCourseOnlySessionMeta).status;
  const watchStartAt = watch('startAt');
  const watchStartDate = watch('startDate');
  const watchFrequency = watch('frequency');
  const watchDayOfTheWeek = watch('dayOfTheWeek');
  const watchDayOfTheMonth = watch('dayOfTheMonth');

  function validateSessionCount(frequencyType: ILiveClassFrequencyForm, noOfSessions: number): any {
    const frequency = frequencyType.frequency as keyof typeof sessionLimits;
    if (frequency in sessionLimits) {
      const maxAllowed = sessionLimits[frequency];
      if (noOfSessions > maxAllowed) {
        return `Maximum number of sessions allowed for ${frequency} is ${maxAllowed}`;
      }
    }
    return null;
  }
  const onSubmitForm: SubmitHandler<ILiveClassFrequencyForm> = (data) => {
    const validationMessage = validateSessionCount(data, numberOfSessions);
    if (validationMessage) {
      setError('frequency', { type: 'custom', message: validationMessage });
      return;
    }
    onSubmit(data);
  };

  React.useEffect(() => {
    if (watchFrequency === SessionFrequencyTypes.oneTime) {
      const newEndDateTime = moment(`${watchStartDate} ${watchStartAt}`, 'YYYY-MM-DD HH:mm:ss').add(
        1,
        'hours',
      );
      setValue('endAt', newEndDateTime.format('HH:mm:ss'));
      setValue('endDate', newEndDateTime.format('YYYY-MM-DD'));
    } else if (watchFrequency === SessionFrequencyTypes.everyDay) {
      const newEndDateTime = moment(
        `${moment(watchStartDate, 'YYYY-MM-DD')
          .add(numberOfSessions - 1, 'days')
          .format('YYYY-MM-DD')} ${watchStartAt}`,
        'YYYY-MM-DD HH:mm:ss',
      ).add(1, 'hours');
      setValue('endAt', newEndDateTime.format('HH:mm:ss'));
      setValue('endDate', newEndDateTime.format('YYYY-MM-DD'));
    } else if (watchFrequency === SessionFrequencyTypes.everyWeek) {
      const startDayOfWeek = moment(watchStartDate, 'YYYY-MM-DD').isoWeekday();
      const selectedDayOfWeek = daysOfTheWeek.filter(
        (dayOfTheWeek) => dayOfTheWeek.value === watchDayOfTheWeek,
      )[0];
      let numberOfWeeks = numberOfSessions;
      if (startDayOfWeek === Number(selectedDayOfWeek.id)) {
        numberOfWeeks = numberOfSessions - 1;
      }
      const newEndDateTime = moment(
        `${moment(watchStartDate, 'YYYY-MM-DD')
          .add(numberOfWeeks, 'week')
          .day(selectedDayOfWeek.id)
          .format('YYYY-MM-DD')} ${watchStartAt}`,
        'YYYY-MM-DD HH:mm:ss',
      ).add(1, 'hours');
      setValue('endAt', newEndDateTime.format('HH:mm:ss'));
      setValue('endDate', newEndDateTime.format('YYYY-MM-DD'));
    } else if (watchFrequency === SessionFrequencyTypes.everyMonth) {
      const startDayOfMonth = moment(watchStartDate, 'YYYY-MM-DD').date();
      const selectedDayOfMonth = daysOfTheMonth.filter(
        (dayOfTheMonth) => dayOfTheMonth.value === watchDayOfTheMonth,
      )[0];
      let numberOfMonths = numberOfSessions;
      if (startDayOfMonth === Number(selectedDayOfMonth.id)) {
        numberOfMonths = numberOfSessions - 1;
      }
      const newEndDateTime = moment(
        `${moment(watchStartDate, 'YYYY-MM-DD')
          .add(numberOfMonths, 'months')
          .date(Number(selectedDayOfMonth.id))
          .format('YYYY-MM-DD')} ${watchStartAt}`,
        'YYYY-MM-DD HH:mm:ss',
      ).add(1, 'hours');
      setValue('endAt', newEndDateTime.format('HH:mm:ss'));
      setValue('endDate', newEndDateTime.format('YYYY-MM-DD'));
    }
  }, [watchStartAt, watchStartDate, watchDayOfTheWeek, watchDayOfTheMonth, watchFrequency]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <h3 className="form-item">Frequency</h3>
      <div className="form-item">
        <Controller
          control={control}
          name="frequency"
          render={({ field }) => (
            <RadioGroup {...field}>
              <Typography variant="h4">Frequency</Typography>
              <Grid container justifyContent="start-flex">
                <Grid item>
                  <FormControlLabel
                    value={SessionFrequencyTypes.oneTime}
                    control={<PrimaryRadio />}
                    label={SessionFrequencyTypes.oneTime}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={SessionFrequencyTypes.everyDay}
                    control={<PrimaryRadio />}
                    label={SessionFrequencyTypes.everyDay}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={SessionFrequencyTypes.everyWeek}
                    control={<PrimaryRadio />}
                    label={SessionFrequencyTypes.everyWeek}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={SessionFrequencyTypes.everyMonth}
                    control={<PrimaryRadio />}
                    label={SessionFrequencyTypes.everyMonth}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
        <ErrorMessage>{errors?.frequency?.message}</ErrorMessage>
      </div>
      <Grid container justifyContent="space-between" spacing={8} className="form-item">
        <Grid item xs>
          <div>
            <Controller
              control={control}
              name="startDate"
              render={({ field: { value, onChange } }) => (
                <PrimaryDatePicker
                  placeholder="select date"
                  label="Start date"
                  value={moment(value, 'YYYY-MM-DD')}
                  onChange={(newValue: any) => {
                    onChange(moment(newValue).format('YYYY-MM-DD'));
                  }}
                />
              )}
            />
            <ErrorMessage>{errors?.startDate?.message}</ErrorMessage>
          </div>
        </Grid>
        <Grid item xs>
          <div>
            <Controller
              control={control}
              name="endDate"
              render={({ field: { value, onChange } }) => (
                <PrimaryDatePicker
                  placeholder="select date"
                  label="End date"
                  minDate={moment(watchStartDate, 'YYYY-MM-DD')}
                  value={moment(value, 'YYYY-MM-DD')}
                  onChange={(newValue: any) => {
                    onChange(moment(newValue).format('YYYY-MM-DD'));
                  }}
                  disabled
                />
              )}
            />
            <ErrorMessage>{errors?.endDate?.message}</ErrorMessage>
          </div>
        </Grid>
      </Grid>
      {watchFrequency === SessionFrequencyTypes.everyWeek ? (
        <Grid container justifyContent="space-between" className="form-item">
          <Grid item xs>
            <div>
              <Controller
                control={control}
                name="dayOfTheWeek"
                render={({ field }) => (
                  <PrimarySelect
                    options={daysOfTheWeek}
                    {...field}
                    placeholder="Select the day"
                    label="Day of the Week"
                  />
                )}
              />
              <ErrorMessage>{errors?.dayOfTheWeek?.message}</ErrorMessage>
            </div>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      {watchFrequency === SessionFrequencyTypes.everyMonth ? (
        <Grid container justifyContent="space-between" className="form-item">
          <Grid item xs>
            <div>
              <Controller
                control={control}
                name="dayOfTheMonth"
                render={({ field }) => (
                  <PrimarySelect
                    {...field}
                    options={daysOfTheMonth}
                    placeholder="Select the day"
                    label="Day of the Month"
                  />
                )}
              />
              <ErrorMessage>{errors?.dayOfTheMonth?.message}</ErrorMessage>
            </div>
          </Grid>
        </Grid>
      ) : (
        ''
      )}

      <Grid container justifyContent="space-between" spacing={8} className="form-item">
        <Grid item xs>
          <div>
            <Controller
              control={control}
              name="startAt"
              render={({ field: { value, onChange } }) => (
                <PrimaryTimePicker
                  value={moment(value, 'HH:mm:ss')}
                  label="Start at"
                  minTime={
                    watchStartDate <= moment().format('YYYY-MM-DD')
                      ? moment(moment().format('HH:mm:ss'), 'HH:mm:ss')
                      : ''
                  }
                  onChange={(newValue: any) => {
                    onChange(moment(newValue).format('HH:mm:ss'));
                  }}
                />
              )}
            />
            <ErrorMessage>{errors?.startAt?.message}</ErrorMessage>
          </div>
        </Grid>
        <Grid item xs>
          <div>
            <Controller
              control={control}
              name="endAt"
              render={({ field: { value, onChange } }) => (
                <PrimaryTimePicker
                  minTime={moment(watchStartAt, 'HH:mm:ss')}
                  value={moment(value, 'HH:mm:ss')}
                  label="End at"
                  onChange={(newValue: any) => {
                    onChange(moment(newValue).format('HH:mm:ss'));
                  }}
                  disabled
                />
              )}
            />
            <ErrorMessage>{errors?.endAt?.message}</ErrorMessage>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLoadingButton loading={loading} type="submit">
            {audienceType === SessionAudienceTypes.courseOnly
              ? courseOnlySessionStatus !== CourseOnlySessionStatus.editing
                ? 'Add to course'
                : 'Update session'
              : '  Save & continue'}
          </PrimaryLoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SessionFrequencyModalForm;
