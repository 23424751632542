import * as yup from 'yup';

const onboardContentModalFormValidation = yup.object().shape({
  landingPageContent: yup.object().shape({
    tagLine: yup.string().trim(),
    title: yup.string().trim(),
    body: yup.string().trim().max(300)
      .label('Body Copy'),
  }),
  groupLandingPageContent: yup.object().shape({
    title: yup.string().trim(),
    body: yup.string().trim(),
  }),
  expertLandingPageContent: yup.object().shape({
    title: yup.string().trim(),
    body: yup.string().trim(),
  }),
  sessionLandingPageContent: yup.object().shape({
    title: yup.string().trim(),
    body: yup.string().trim(),
  }),
  articleLandingPageContent: yup.object().shape({
    title: yup.string().trim(),
    body: yup.string().trim(),
  }),
  contentLandingPageContent: yup.object().shape({
    title: yup.string().trim(),
    body: yup.string().trim(),
  }),
});
export default onboardContentModalFormValidation;
