/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Controller, SubmitHandler, useFieldArray, useFormContext,
} from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid, IconButton, RadioGroup, Typography,
} from '@mui/material';
import { Help } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import {
  GroupTypes,
  SessionLanguages, SessionType, CourseTypes, LiveClassTypes, SessionAudienceTypes,
} from 'config';
import { IOption } from 'interfaces';
import { getOptionsFromEnum } from 'utils/data-utils';
import {
  CustomRichTextField,
  ErrorMessage,
  Label,
  PrimaryCheckbox,
  PrimaryLiteButton,
  PrimaryLoadingButton,
  PrimaryNumericField,
  PrimaryRadio,
  PrimarySelect,
  PrimaryTextField,
  PrimaryTooltip,
  SliderInput,
} from 'components';
import { IGroupAvatarWithAction } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/interface';

import { selectFilteredContent, selectLoader as selectContentLoader } from '../../../../../../../content/selector';
import {
  IBadge,
  IWaitingMedia, ISessionFormAvatarWithAction, IInvitedParticipants,
} from '../../../../../../interface';
import { CourseOnlySessionStatus, ICreateSessionForm, ModalState } from '../../../../interface';
import { SelectGroupModal, SelectContentModal } from '../../../select-modals';
import { SelectedGroupTable, SelectedContentTable } from '../../../selected-tables';
import SessionTypeModal from '../../../session-type-modal';
import WaitingRoomMedia from '../../../waiting-room-media-modal';
import './index.scss';

import {
  selectCourseOnlySessionMeta,
  selectCreateSession,
  selectEntId, selectExpertId, selectFilteredGroups,
  selectInvitations,
  selectLoader, selectModalState, selectSessionInformation, selectSubStateLoader,
} from '../../../../selector';
import { selectId as selectFormExpertId, selectAccountType } from '../../../../../../../../selector';
import ViewBadge from '../../../view-badge';
import { selectFilteredForms, selectStateLoader } from '../../../../../../../forms/selector';
import SelectFormModal from '../../../select-modals/select-form-modal';
import SelectedFormTable from '../../../selected-tables/selected-form-table';
import ConfigFormsModal from '../../../select-modals/config-form-modal';
import { viewFormsActions } from '../../../../../../../forms/slice';
import { FormTypes } from '../../../../../../../forms/interface';
import { createSessionActions } from '../../../../slice';
import { IContentAvatarWithAction } from '../../../../../../../content/interface';
import { viewContentActions } from '../../../../../../../content/slice';
import ResendInvite from '../../../../../resend-invite';
import InvitedParticipants from '../invite-participant';
import CoursePrerequisites from '../../../session-details/components/course-prerequisites';
import ViewInviteUsersModal from '../invite-users-modal';

interface SessionInformationFormProps {
  onSubmit: SubmitHandler<ICreateSessionForm>;
  editMode: boolean,
  courseOnlySession: boolean,
}
const SessionInformationForm = ({
  onSubmit, editMode, courseOnlySession,
}: SessionInformationFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }, setValue, getValues, watch, reset, clearErrors,
  } = useFormContext<ICreateSessionForm>();

  const languageOptions: IOption[] = getOptionsFromEnum(SessionLanguages);
  const sessionDescriptions: { [key in SessionType]: string } = {
    [SessionType.digitalSession]: 'Digital session is an analysis session where a user uploads up to 4 videos of themselves or team and the coach responds with a coaching video analyzing this footage and providing insights, tips and feedback. The footage is the users to keep and review whenever they need it.',
    [SessionType.liveSession]: 'The LIVE analysis session allows a user to receive real time feedback  from an expert. The expert will talk to the user in real time providing them with feedback and analysis to help them improve. Just like face to face coaching, only digitally.',
    [SessionType.content]: 'Content only session type will allow a user to access a collection of free or premium content, which once purchased they will be able to access anytime and make use of it',
    [SessionType.liveClass]: 'A LIVE class is just like a LIVE session where there can be multiple users as well as observers.This allows users to receive real time feedback from an expert. The expert will talk to the users in real time providing them with feedback and analysis to help them improve.',
    [SessionType.course]: 'Courses are a collection of sessions/content/forms etc. with set modules which a user can complete at their own pace and earn badges and accreditation upon completion.',
  };

  const sessionSampleDescription = {
    [SessionType.digitalSession]: 'My digital coaching session allows you to choose the skill or area of your game you want me to help you improve. I will analyse your media, review all parts of your game with personal feedback, share drills and work ons to strengthen your game',
    [SessionType.liveSession]: 'Let\'s catch up on a Live Session. Together we can review your skills through video analysis and annotation. I\'ll share all my secrets and give you all the work ons to help you improve your game.',
    [SessionType.liveClass]: 'Join in to my Live Class. Together we can review your skills through video analysis and annotation. I\'ll share all my secrets and give you all the work ons to help you improve your game.',
    [SessionType.content]: '',
  };
  const maxChimeUsers = 250;
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const courseType = searchParams.get('courseType');
  const courseCount = searchParams.get('courseCount');
  // selectors
  const { bookedSessions } = useSelector(selectCreateSession);
  const {
    description: sessionDescription,
    sessionType,
    numberOfParticipants,
    isObserverAllowed,
    message,
    groups,
    forms,
    media,
    waitingRoomMedia,
    sessionDuration,
    invitePeople: inviteEmails,
    numberOfSessions,
  } = useSelector(
    selectSessionInformation,
  );
  const sessionNote = sessionDescriptions[sessionType as SessionType] || '';
  const expertAccountType = useSelector(selectAccountType).accountType;
  const expertId = useSelector(selectExpertId);
  const formExpertId = useSelector(selectFormExpertId);
  const entId = useSelector(selectEntId);
  const loading = useSelector(selectLoader);
  const invitations = useSelector(selectInvitations);
  const subStateLoading = useSelector(selectSubStateLoader);
  const modalState = useSelector(selectModalState);
  const { status } = useSelector(selectCourseOnlySessionMeta);

  // watches
  const watchCourseType = watch('courseType');
  const watchUserSettings = watch('userSettings');
  const allowMessaging = watch('userSettings.allowMessaging');
  const watchLiveClassType = watch('liveClassType');
  const watchNumberOfParticipants = watch('numberOfParticipants');
  const watchNumberOfObservers = watch('numberOfObservers');
  const watchIsObserversAllowed = watch('isObserverAllowed');
  const watchDescription = watch('description');

  // states
  const [description, setDescription] = React.useState(getValues('description'));
  const [badge, setBadge] = React.useState<IBadge>(getValues('badge'));
  const [audience, setAudience] = React.useState(getValues('audienceType'));
  const [invitePeopleError, setInvitePeopleError] = React.useState<string>('');
  const [invitePeople, setInvitePeople] = React.useState<string[]>(inviteEmails ?? []);
  const [selectedPerson, setSelectedPerson] = React.useState<string>('');
  const [resendInvitationId, setResendInvitationId] = React.useState<string>('');
  const [publicGroupSelected, setPublicGroupSelected] = React.useState<boolean>(false);
  const courseTypeOptions = getOptionsFromEnum(CourseTypes);

  // invite participants for streaming session
  const { fields, append, remove } = useFieldArray({ name: 'invitedParticipants', control });
  const handleChange = (index: number, participant: IInvitedParticipants) => {
    setValue(`invitedParticipants.${index}`, participant);
  };
  const addParticipant = () => {
    append({
      firstName: '',
      email: '',
    });
  };

  const removeParticipant = (index: number) => {
    remove(index);
  };

  // group modal
  const [selectedGroups, setSelectedGroups] = React.useState<IGroupAvatarWithAction[]>(
    groups.map((group) => ({
      ...group, isAdded: true,
    })),
  );
  const filteredGroup = useSelector(selectFilteredGroups).result;
  const groupTotal = useSelector(selectFilteredGroups).total;

  // form modal
  const [selectedForms, setSelectedForms] = React.useState<ISessionFormAvatarWithAction[]>(
    forms.map((form) => ({
      ...form, isAdded: true,
    })),
  );
  const filteredForm = useSelector(selectFilteredForms).result.filter(
    (form) => form.availability === 'Ready to use',
  );
  const formTotal = useSelector(selectFilteredForms).total;
  const formLoading = useSelector(selectStateLoader);

  // media modal
  const [selectedContents, setSelectedContents] = React.useState<IContentAvatarWithAction[]>(
    media?.map((content) => ({
      ...content,
      isAdded: true,
    })),
  );
  const filteredContent = useSelector(selectFilteredContent).result;
  const totalContent = useSelector(selectFilteredContent).total;
  const contentLoading = useSelector(selectContentLoader);

  const [openConfigFormModal, setOpenConfigFormModal] = React.useState(false);
  const [parentCourseType, setParentCourseType] = React.useState<CourseTypes>(CourseTypes.lsm);
  const durationTimes: IOption[] = React.useMemo(() => {
    const result = [];
    for (let index = 0; index < 8; index++) {
      const selectMinutes = ((index + 1) * 30);
      const hours = (selectMinutes / 60);
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);
      result.push({ id: selectMinutes, value: `${selectMinutes}`, text: `${rhours > 0 ? `${rhours} hour(s)` : ''} ${rhours > 0 && rminutes > 0 ? ' and ' : ''} ${rminutes > 0 ? ` ${rminutes} minute(s)` : ''}` });
    }
    return result;
  }, []);
  const getSelectedDuration = React.useCallback((minutes: string) => durationTimes.filter(
    (item: IOption) => item.value === minutes,
  )[0], [durationTimes]);
  const [initialDurationTime, setInitialDurationTime] = React.useState<IOption>(
    sessionDuration === 0
      ? durationTimes[0] : getSelectedDuration(sessionDuration.toString()),
  );

  const handleAudienceChange = (event: any) => {
    setValue('audienceType', event.target.value);
    setAudience(event.target.value);
  };
  const handleWaitingMedia = (content: IWaitingMedia) => {
    setValue('waitingRoomMedia', content);
  };
  const handleSelectDuration = (minutes: string) => {
    setValue('sessionDuration', Number(minutes));
    setInitialDurationTime(getSelectedDuration(minutes));
  };
  // handle invited users
  const addUser = () => {
    setInvitePeopleError('');
    clearErrors('invitePeople');
    if (isEmail(selectedPerson)) {
      const inviteDetails = invitations.filter((invitation) => invitation.userEmail
        === selectedPerson);
      const invitePending = invitePeople.filter((email) => email
        === selectedPerson);
      if (invitePending.length) {
        setInvitePeopleError('User email address is already added');
      }
      if (inviteDetails.length) {
        setResendInvitationId(inviteDetails[0]._id);
        dispatch(createSessionActions.setModalState(ModalState.resendInvite));
      } else {
        const temp = Array.from(new Set([...invitePeople, selectedPerson]));
        setInvitePeople(temp);
        setValue('invitePeople', temp);
        setSelectedPerson('');
      }
    } else {
      setInvitePeopleError('Invalid email');
    }
  };
  const removeUser = (email: string) => {
    const temp = invitePeople.filter((item) => item !== email);
    setInvitePeople(temp);
    setValue('invitePeople', temp);
  };
  const resend = () => {
    dispatch(createSessionActions.resendInvite(resendInvitationId));
  };
  // handle groups selection
  const updateGroups = (groupList: IGroupAvatarWithAction[]) => {
    setSelectedGroups(groupList);
  };
  const handleGroupRemove = (groupId: string) => {
    setSelectedGroups((prev) => ([...prev.filter((group) => (group._id !== groupId))]));
  };
  const searchGroup = (keyword: string, page: number) => {
    if (expertId && entId) {
      dispatch(createSessionActions.getFilteredGroups({
        keyword, page: (page + 1), limit: 3, groupType: ((editMode || publicGroupSelected) ? GroupTypes.public : 'All'),
      }));
    }
  };

  // handle content selection
  const updateContents = (contentList: IContentAvatarWithAction[]) => {
    setSelectedContents(contentList);
  };
  const handleContentRemove = (contentId: string) => {
    setSelectedContents((prev) => ([...prev.filter((content) => (content.id !== contentId))]));
  };
  const searchContent = (keyword: string, page: number) => {
    if (expertId && entId) {
      dispatch(viewContentActions.getContent({
        keyword,
        page: (page + 1),
        limit: 3,
        type: ['image', 'video', 'document'],
        sortBy: 'uploaded',
      }));
    }
  };
  // handle forms selection
  const updateForms = (formList: ISessionFormAvatarWithAction[]) => {
    setSelectedForms(formList);
    if (formList.filter((form) => (form.isAdded)).length > 0) {
      // setOpenConfigFormModal(true);
    }
  };
  const handleFormRemove = (formId: string) => {
    setSelectedForms((prev) => ([...prev.filter((form) => (form.id !== formId))]));
  };

  const handleConfigFormModalCancel = () => {
    setOpenConfigFormModal((preValue) => !preValue);
  };
  const searchForm = (keyword: string, page: number) => {
    if (formExpertId) {
      dispatch(viewFormsActions.getFilteredForms({
        keyword,
        sortBy: 'Default',
        page: page + 1,
        limit: 3,
        formType: (sessionType === SessionType.course && watchCourseType === CourseTypes.lsm)
          || watchUserSettings.allowMessaging === false
          || (sessionType === SessionType.liveClass
            && watchLiveClassType === LiveClassTypes.streaming)
          ? FormTypes.LMS_FORM : '',
      }));
    }
  };

  // handle badge
  const handleBadge = (newBadge: IBadge) => {
    setBadge(newBadge);
    setValue('badge', newBadge);
  };
  const handleBadgeRemove = () => {
    setBadge({ badgeTitle: '', badgeDescription: '', badgeImgUrl: '' });
  };

  React.useEffect(() => {
    if (
      (sessionType === SessionType.course && watchCourseType === CourseTypes.lsm)
      || (sessionType === SessionType.liveClass && !allowMessaging)) {
      setValue('message', '-');
    } else if (getValues('message') === '-') {
      setValue('message', '');
    }
  }, [setValue, getValues, watchCourseType, sessionType, allowMessaging]);

  React.useEffect(() => {
    if (watchLiveClassType === LiveClassTypes.streaming) {
      setValue('numberOfParticipants', 9999999);
      setValue('isObserverAllowed', false);
      setValue('message', '-');
    } else {
      setValue('numberOfParticipants', numberOfParticipants);
      setValue('isObserverAllowed', isObserverAllowed);
      setValue('message', message);
    }
  }, [watchLiveClassType]);

  // update uploading setting when messaging is disabled
  React.useEffect(() => {
    if (!watchUserSettings.allowMessaging) {
      setValue('userSettings.allowUpload', false);
    }
  }, [watchUserSettings]);

  // if the participant is zero then there should be observers
  React.useEffect(() => {
    if (watchNumberOfParticipants === 0
      && (watchNumberOfObservers === 0 || !watchIsObserversAllowed)
    ) {
      setValue('isObserverAllowed', true);
      setValue('numberOfObservers', 1);
    }
  }, [watchNumberOfParticipants, watchNumberOfObservers, watchIsObserversAllowed, setValue]);

  React.useEffect(() => {
    const currentTotalUsers = watchNumberOfParticipants + watchNumberOfObservers;
    if (currentTotalUsers > maxChimeUsers) {
      const maxParticipants = maxChimeUsers - watchNumberOfObservers;
      if (watchNumberOfParticipants > maxParticipants) {
        if (watchNumberOfParticipants > maxChimeUsers) {
          setValue('numberOfObservers', 0);
          setValue('numberOfParticipants', maxChimeUsers);
        } else {
          setValue('numberOfObservers', watchNumberOfObservers - (watchNumberOfParticipants - maxParticipants));
        }
      }
    }
  }, [watchNumberOfParticipants]);

  React.useEffect(() => {
    const currentTotalUsers = watchNumberOfParticipants + watchNumberOfObservers;
    if (currentTotalUsers > maxChimeUsers) {
      const maxObservers = maxChimeUsers - watchNumberOfParticipants;
      if (watchNumberOfObservers > maxObservers) {
        if (watchNumberOfObservers > maxChimeUsers) {
          setValue('numberOfParticipants', 0);
          setValue('numberOfObservers', maxChimeUsers);
        } else {
          setValue('numberOfParticipants', watchNumberOfParticipants - (watchNumberOfObservers - maxObservers));
        }
      }
    }
  }, [watchNumberOfObservers]);

  // updating session type
  React.useEffect(() => {
    setValue('sessionType', sessionType);
  }, [sessionType]);

  // updating the selected forms in the form
  React.useEffect(() => {
    const list = selectedForms.map((form) => {
      const { isAdded, ...remainP } = form; return remainP;
    });
    setValue('forms', list);
  }, [selectedForms]);

  // updating the selected groups in the form
  React.useEffect(() => {
    setValue('groups', selectedGroups.map((group) => { const { isAdded, ...remainP } = group; return remainP; }));
  }, [selectedGroups]);

  // updating the selected contents in the form
  React.useEffect(() => {
    setValue('media', selectedContents.map((content) => { const { isAdded, ...remainP } = content; return remainP; }));
  }, [selectedContents]);

  // get the filtered group based on the status
  React.useEffect(() => {
    if (expertId) {
      searchGroup('', 0);
      searchContent('', 0);
    }
  }, [editMode, expertId]);

  React.useEffect(() => {
    searchForm('', 0);
  }, [sessionType, watchCourseType, watchUserSettings]);

  // disabled user messaging if course only session live class
  React.useEffect(() => {
    if (courseOnlySession && sessionType === SessionType.liveClass) {
      setValue('userSettings.allowUpload', false);
    }
  }, []);

  React.useEffect(() => {
    if (!editMode) {
      reset();
      setValue('sessionType', sessionType);
      if (sessionDescription === '') {
        if (sessionType === SessionType.digitalSession) {
          setDescription(sessionSampleDescription[sessionType]);
          setValue('message', 'This session puts you in the driving seat, allowing you to choose exactly what you want me to help you improve. To get started, you can grab your phone, record the skill or action, game or training footage and upload the recordings in the landscape to the chat tool and I\'ll get to work. I look forward to working with you.');
        } else if (sessionType === SessionType.liveSession) {
          setValue('sessionDuration', 30);
          setDescription(sessionSampleDescription[sessionType]);
          setValue('message', 'Hey, thanks for booking a session with me. To get started, you can grab your phone, record the skill or action, game or training footage and upload the recordings in the landscape to the chat tool and I\'ll get to work. I look forward to working with you.');
        } else if (sessionType === SessionType.liveClass) {
          setDescription(sessionSampleDescription[sessionType]);
        } else if (sessionType === SessionType.content) {
          setDescription(sessionSampleDescription[sessionType]);
          setValue('message', '-');
        } else if (Object.values(sessionSampleDescription).map((value) => value.replace(/(<([^>]+)>)/ig, '').trim()).includes(watchDescription.replace(/(<([^>]+)>)/ig, '').trim())) {
          setDescription('');
        }
      }
    }
    if (courseOnlySession) {
      setValue('numberOfSessions', editMode ? numberOfSessions : courseCount ? Number(courseCount) : numberOfSessions);
    }
  }, [editMode, sessionType]);

  React.useEffect(() => {
    if (courseOnlySession && courseType) {
      setParentCourseType(CourseTypes[courseType as keyof typeof CourseTypes]);
      if (status !== CourseOnlySessionStatus.editing) {
        dispatch(createSessionActions.setSessionType(
          courseType === CourseTypes.lsm ? SessionType.liveClass
            : SessionType.liveSession,
        ));
      }
    }
  }, [courseType]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item">
        Session Information
        {}
      </h3>
      <div className="form-item">
        Select session type
        {' '}
        <b>{sessionType}</b>
        {!editMode ? <SessionTypeModal inner courseOnlySession={courseOnlySession} courseType={parentCourseType} /> : ''}

      </div>
      <div className="form-item">
        <Typography variant="body1">
          {sessionNote}
        </Typography>
      </div>
      {sessionType === SessionType.liveClass && (
        <div className="form-item">
          <Controller
            control={control}
            name="liveClassType"
            render={({ field }) => (
              <>
                <Typography variant="body2" sx={{ mb: 0.5, color: 'text.secondary' }}>
                  Meeting Type
                </Typography>
                <RadioGroup {...field}>
                  <Grid container justifyContent="start-flex">
                    <Grid item xs={12} sm="auto">
                      <FormControlLabel
                        value={LiveClassTypes.interactive}
                        control={<PrimaryRadio />}
                        label={LiveClassTypes.interactive}
                        disabled={editMode && bookedSessions > 0}
                      />
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <FormControlLabel
                        value={LiveClassTypes.streaming}
                        control={<PrimaryRadio />}
                        label={LiveClassTypes.streaming}
                        disabled={editMode && bookedSessions > 0}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </>
            )}
          />
          <ErrorMessage>{errors?.liveClassType}</ErrorMessage>
        </div>
      )}

      <div className="form-item">
        <Controller
          control={control}
          name="audienceType"
          render={({ field }) => (
            <RadioGroup {...field} onChange={handleAudienceChange}>
              <Label>Audience</Label>
              {
                courseOnlySession ? (
                  <Grid container justifyContent="start-flex">

                    <Grid item>
                      <FormControlLabel value={SessionAudienceTypes.courseOnly} control={<PrimaryRadio />} label="Course only session" disabled={editMode} />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justifyContent="start-flex">
                    <Grid item>
                      <FormControlLabel value={SessionAudienceTypes.public} control={<PrimaryRadio />} label="Public" disabled={editMode} />
                    </Grid>
                    {watchLiveClassType !== LiveClassTypes.streaming && (
                      <Grid item>
                        <FormControlLabel value={SessionAudienceTypes.private} control={<PrimaryRadio />} label="Invite only" disabled={editMode} />
                      </Grid>
                    )}
                    {expertAccountType === 'subscription' && (
                      <>
                        <Grid item>
                          <FormControlLabel sx={{ marginRight: '0.5rem' }} value={SessionAudienceTypes.subscription} control={<PrimaryRadio />} label="Subscription" disabled={editMode} />
                          <PrimaryTooltip title={(
                            <Typography variant="body1" sx={{ fontSize: '14px', color: '#fff' }}>
                              This will allow you to set out different
                              price points to your subscribers and non-subscribers
                            </Typography>
                          )}
                          >
                            <IconButton sx={{ marginRight: '0.5rem' }}><InfoIcon /></IconButton>

                          </PrimaryTooltip>
                        </Grid>
                        <Grid item>
                          <FormControlLabel sx={{ marginRight: '0.5rem' }} value={SessionAudienceTypes.subscriptionOnly} control={<PrimaryRadio />} label="Subscription only" disabled={editMode} />
                          <PrimaryTooltip title={(
                            <Typography variant="body1" sx={{ fontSize: '14px', color: '#fff' }}>
                              This option will make this session only visible for your subscribers
                            </Typography>
                          )}
                          >
                            <IconButton sx={{ marginRight: '0.5rem' }}><InfoIcon /></IconButton>

                          </PrimaryTooltip>
                        </Grid>
                      </>

                    )}

                  </Grid>
                )
              }

            </RadioGroup>
          )}
        />
        <ErrorMessage>{errors?.audienceType?.message}</ErrorMessage>
      </div>
      {audience === SessionAudienceTypes.private ? (
        <>
          <div className="form-item">
            <Controller
              control={control}
              name="invitePeople"
              render={({ field }) => (
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h4">Invite People</Typography>
                  </Grid>
                  <Grid item sx={{ width: '100%' }}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                      <Grid item xs>
                        <PrimaryTextField
                          {...field}
                          placeholder="Enter email address"
                          value={selectedPerson}
                          onChange={(e: any) => setSelectedPerson(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs>
                        <PrimaryLiteButton onClick={addUser}>Add user</PrimaryLiteButton>
                      </Grid>
                    </Grid>
                    <ErrorMessage>
                      {errors?.invitePeople?.message || invitePeopleError}
                    </ErrorMessage>
                  </Grid>
                  <Grid item sx={{ marginTop: '0.5rem' }}>
                    <Grid container spacing={2}>
                      {invitePeople.length > 0
                      && invitePeople.map((people, index) => (
                        <Grid item key={people + index.toString()}>
                          <Grid container alignItems="center" sx={{ backgroundColor: '#F2F2F2', padding: '0.3rem' }}>
                            <Grid item sx={{ paddingLeft: '1rem' }}>
                              <Typography variant="h4">{people}</Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="close"
                                sx={{ float: 'right' }}
                                onClick={() => removeUser(people)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            />
            <ViewInviteUsersModal invitations={invitations} />
          </div>

          <ResendInvite
            email={selectedPerson}
            open={modalState === ModalState.resendInvite}
            onClose={() => { dispatch(createSessionActions.setModalState(ModalState.close)); setResendInvitationId(''); }}
            resend={resend}
            loading={subStateLoading}
          />
        </>

      ) : ''}

      {sessionType === SessionType.course && (
        <div className="form-item">
          <div className="inline-wrapper">
            <Label>Course Type</Label>
            <PrimaryTooltip title={watchCourseType === CourseTypes.lsm ? 'LMS course' : 'interactive course'}><IconButton><Help /></IconButton></PrimaryTooltip>
          </div>

          <Controller
            control={control}
            name="courseType"
            render={({ field }) => (
              <PrimarySelect {...field} label="" options={courseTypeOptions} placeholder="Select course Type" />
            )}
          />
          <ErrorMessage>{errors?.courseType?.message}</ErrorMessage>
        </div>
      )}
      {((sessionType === SessionType.liveClass)
        && watchLiveClassType === LiveClassTypes.interactive) && (
          <div className="form-item">
            <Label>  User settings</Label>
            <Controller
              control={control}
              name="userSettings"
              render={({ field }) => (
                <FormGroup row>
                  <FormControlLabel
                    control={(
                      <PrimaryCheckbox
                        checked={!field.value.allowUpload || !watchUserSettings.allowMessaging}
                        onChange={(event: any, checked: boolean) => {
                          field.onChange({ ...field.value, allowUpload: !checked });
                        }}
                        disabled={
                          ((sessionType === SessionType.liveClass) && courseOnlySession) || editMode
                        }
                      />
                    )}
                    label="Disable user uploads"
                  />
                  <FormControlLabel
                    control={(
                      <PrimaryCheckbox
                        checked={!field.value.allowMessaging}
                        onChange={(event: any, checked: boolean) => {
                          field.onChange({ ...field.value, allowMessaging: !checked });
                        }}
                        disabled={
                          ((sessionType === SessionType.liveClass) && courseOnlySession) || editMode
                        }
                      />
                    )}
                    label="Disable user messaging"
                  />
                </FormGroup>
              )}
            />
            <ErrorMessage>{errors?.userSettings?.allowMessaging?.message}</ErrorMessage>
            <ErrorMessage>{errors?.userSettings?.allowUpload?.message}</ErrorMessage>
          </div>
      )}

      <div className="form-item">
        <Controller
          control={control}
          name="title"
          render={({ field }) => <PrimaryTextField {...field} label="Session name" />}
        />

        <ErrorMessage>{errors?.title?.message}</ErrorMessage>
      </div>

      <div className="form-item">
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <>
              <Label>
                Session description
              </Label>
              <CustomRichTextField {...field} placeholder="Type here..." defaultValue={description} />
            </>
          )}
        />
        <ErrorMessage>{errors?.description?.message}</ErrorMessage>
      </div>
      {!(watchLiveClassType === LiveClassTypes.streaming
        || (watchCourseType === CourseTypes.lsm && sessionType === SessionType.course)
        || (sessionType === SessionType.liveClass && !watchUserSettings.allowMessaging)
        || sessionType === SessionType.content) && (
          <div className="form-item">
            <Controller
              control={control}
              name="message"
              render={({ field }) => <PrimaryTextField {...field} multiline rows={5} label="Session message" />}
            />
            <ErrorMessage>{errors?.message?.message}</ErrorMessage>
          </div>
      )}

      <div className="form-item">
        <Grid
          container
          flexDirection="row"
          alignItems="start"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={6}>
            <Controller
              control={control}
              name="language"
              render={({ field }) => (
                <PrimarySelect {...field} label="Session language" options={languageOptions} placeholder="Select language" />
              )}
            />
            <ErrorMessage>{errors?.language?.message}</ErrorMessage>
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="numberOfSessions"
              render={({ field }) => (
                <PrimaryNumericField
                  {...field}
                  max={99999}
                  label={sessionType === SessionType.course ? 'Number of courses' : 'Number of sessions'}
                />
              )}
            />
            <ErrorMessage>{errors?.numberOfSessions?.message}</ErrorMessage>
          </Grid>
        </Grid>
      </div>

      { sessionType !== SessionType.liveClass && editMode
        && (
        <div className="form-item">
          <Controller
            control={control}
            name="bookedSessions"
            render={({ field }) => (
              <PrimaryNumericField
                {...field}
                max={99999}
                label="Booked sessions"
                value={bookedSessions}
                disabled
              />
            )}
          />
        </div>
        )}

      {sessionType === SessionType.liveSession ? (
        <div className="form-item">
          <div className="inline-wrapper">
            <Typography variant="body1">Session duration</Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', ml: 0.5 }}>
              (How long will one session last?)
            </Typography>
          </div>
          <PrimarySelect
            options={durationTimes}
            value={`${initialDurationTime.value}`}
            onChange={(e: { target: { value: string; }; }) => {
              handleSelectDuration(e.target.value);
            }}
          />
          <ErrorMessage>{errors?.sessionDuration?.message}</ErrorMessage>
        </div>
      ) : null}
      {(sessionType === SessionType.liveClass
        && watchLiveClassType === LiveClassTypes.interactive) ? (
          <>
            <div className="form-item live-class-slider">
              <Controller
                control={control}
                name="numberOfParticipants"
                render={({ field }) => (
                  <SliderInput
                    max={maxChimeUsers}
                    min={0}
                    step={1}
                    defaultValue={2}
                    marks={
                    [{ value: 0, label: '0' }, { value: maxChimeUsers, label: maxChimeUsers.toString() }]
                  }
                    {...field}
                    label="Select number of participants"
                  />
                )}
              />
              <ErrorMessage>{errors?.numberOfParticipants?.message}</ErrorMessage>
            </div>
            <div className="form-item">
              <Controller
                control={control}
                name="isObserverAllowed"
                render={({ field }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <PrimaryCheckbox
                          disabled={watchNumberOfParticipants === 0}
                          checked={field.value}
                          onChange={(event: any) => {
                            field.onChange(event);
                          }}
                        />
                    )}
                      label="Allow observers to this session"
                    />
                  </FormGroup>
                )}
              />
              <ErrorMessage>{errors?.isObserverAllowed?.message}</ErrorMessage>
            </div>

          </>

        ) : ''}

      {(watchIsObserversAllowed
        && sessionType === SessionType.liveClass
        && watchLiveClassType === LiveClassTypes.interactive)
        ? (
          <div className="form-item live-class-slider">
            <Controller
              control={control}
              name="numberOfObservers"
              render={({ field }) => (
                <SliderInput
                  max={maxChimeUsers}
                  min={watchNumberOfParticipants === 0 ? 1 : 0}
                  step={1}
                  defaultValue={0}
                  marks={
                    [{ value: 0, label: '0' }, { value: maxChimeUsers, label: maxChimeUsers.toString() }]
                  }
                  {...field}
                  label={(
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography variant="body1">
                        Select number of observers
                      </Typography>
                      <PrimaryTooltip
                        title={(
                          <Typography variant="body1" sx={{ fontSize: '14px', color: '#fff' }}>
                            The combined total of participants and observers
                            should not exceed 250.
                          </Typography>
                        )}
                      >
                        <IconButton sx={{ marginRight: '0.5rem', color: 'primary.main' }}><InfoIcon /></IconButton>
                      </PrimaryTooltip>
                    </Box>
                  )}
                />
              )}
            />
            <ErrorMessage>{errors?.numberOfObservers?.message}</ErrorMessage>
          </div>
        ) : ''}
      {(sessionType === SessionType.liveClass || sessionType === SessionType.liveSession) ? (
        <div className="form-item">
          <Label>Waiting room media</Label>
          <WaitingRoomMedia selectedMedia={waitingRoomMedia} handleAdd={handleWaitingMedia} />
        </div>
      ) : ''}
      {watchLiveClassType === LiveClassTypes.streaming ? (
        <div className="form-item">
          <Typography variant="h4" className="form-item">Invite Participants</Typography>

          {fields.map((item, i) => (
            <div key={item.id}>
              <div className="form-item">
                <InvitedParticipants
                  handleChange={handleChange}
                  index={i}
                  removeParticipant={removeParticipant}
                  errors={errors}
                  participantInit={item}
                />
              </div>

            </div>

          ))}

          <PrimaryLiteButton sx={{ margin: '0.5rem 0' }} onClick={addParticipant} disabled={fields.length >= 5}>
            <AddIcon />
            Add new participant
          </PrimaryLiteButton>
        </div>

      ) : ''}

      {(audience !== SessionAudienceTypes.private
        && audience !== SessionAudienceTypes.courseOnly) && (
          <div className="form-item">
            <Label sx={{ mb: '0.5rem' }}>Assign to group</Label>
            {selectedGroups.length > 0 ? (
              <SelectedGroupTable
                items={selectedGroups}
                handelRemove={handleGroupRemove}
              />
            ) : ''}
            <div>
              <SelectGroupModal
                selectedGroups={selectedGroups}
                filteredGroup={filteredGroup}
                total={groupTotal}
                loading={subStateLoading}
                handleUpdate={updateGroups}
                searchFunction={searchGroup}
                selectOne
                multiplePublicGroups
                setPublicGroupSelected={setPublicGroupSelected}
                publicGroupSelected={publicGroupSelected}
              />
            </div>

          </div>
      )}

      {(sessionType === SessionType.liveClass || sessionType === SessionType.liveSession
        || sessionType === SessionType.digitalSession) ? (
          <div className="form-item">
            <Label sx={{ mb: '0.5rem' }}>Session forms</Label>
            {selectedForms.length > 0 ? (
              <SelectedFormTable
                items={selectedForms}
                handelRemove={handleFormRemove}
              />
            ) : ''}
            <div>
              <SelectFormModal
                selectedForms={selectedForms}
                filteredForm={filteredForm}
                total={formTotal}
                loading={formLoading}
                handleUpdate={updateForms}
                searchFunction={searchForm}
              />
              <ConfigFormsModal
                key={JSON.stringify(selectedForms)}
                open={openConfigFormModal}
                handleClose={handleConfigFormModalCancel}
                selectedItems={selectedForms}
                handleUpdate={updateForms}
              />
            </div>

          </div>
        ) : ''}

      {(sessionType !== SessionType.course) ? (
        <div className="form-item">
          <Label sx={{ mb: '0.5rem' }}>Session media</Label>
          {selectedContents.length > 0 ? (
            <SelectedContentTable
              items={selectedContents}
              handelRemove={handleContentRemove}
            />
          ) : ''}
          <div>
            <SelectContentModal
              filteredContent={filteredContent}
              selectedContents={selectedContents}
              total={totalContent}
              loading={contentLoading}
              handleUpdate={updateContents}
              searchFunction={searchContent}
            />
          </div>

          <div>
            <ErrorMessage>{errors?.media?.message}</ErrorMessage>
          </div>

        </div>
      ) : ''}
      {
        (sessionType === SessionType.course) ? (
          <div className="form-item">
            <Label>Badges (received by the user at course completion)</Label>
            <ViewBadge handleBadge={handleBadge} handleBadgeRemove={handleBadgeRemove} badge={badge} formTitle="Add badge" />
            <ErrorMessage>
              {errors?.badge?.badgeTitle?.message}
            </ErrorMessage>
          </div>
        ) : ''
      }

      {sessionType === SessionType.course && <CoursePrerequisites />}

      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLoadingButton type="submit" loading={loading}>
            Save & continue
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </form>
  );
};

export default SessionInformationForm;
