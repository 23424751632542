import { IGroupAvatar } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/interface';
import { IFiltered } from 'interfaces/api-response';
import {
  IWaitingMedia, IBadge, IAvailableTime, IModule, IGoal,
  ISessionFormAvatar, IInvitation, IInvitedParticipants,
  SessionDocument,
} from '../../interface';
import { IContentAvatar } from '../../../content/interface';

export enum ModalState {
  resendInvite = 'RESEND_INVITE',
  close = 'CLOSE',
}
export enum CourseOnlySessionStatus {
  creating = 'CREATING',
  editing = 'EDITING',
  done = 'DONE',
}

export interface ISessionItemData {
  id: string;
  title: string;
  thumbnailUrl: string;
  sessionType: string;
  audienceType: string;
  isAdded:boolean
}

export interface IGroupItemData {
  id:string,
  title:string,
  thumbnailUrl:string,
  isAdded:boolean
}
export interface IContentItemData {
  id: string;
  title: string;
  contentUrl: string;
  thumbnail:string;
  fileType: string;
  description: string;
  fileCategory:string,
  isAdded:boolean

}
export interface ISkillItemData {
  id:string,
  name:string,
  isAdded:boolean
}

export interface ICreateSessionForm {
  sessionType:string,
  liveClassType?:string,
  audienceType:string,
  userSettings:{ allowUpload:boolean,
    allowMessaging:boolean },
  courseType:string,
  title:string,
  description:string,
  message:string,
  numberOfSessions:number,
  sessionDuration:number,
  language:string,
  invitePeople?:string[],
  invitedParticipants?:IInvitedParticipants[],
  inviteUrl?:string,
  password?:string,
  groups:IGroupAvatar[],
  forms:ISessionFormAvatar[],
  media:IContentAvatar[],
  waitingRoomMedia?: IWaitingMedia,
  isObserverAllowed?:boolean,
  numberOfParticipants:number,
  numberOfObservers:number,
  badge:IBadge
  prerequisites?: { id: string; title: string; _id?: string; }[];
  bookedSessions?: number;
}

export interface ISessionFeeForm {
  fee:string,
  currency:string,
  discount:string,
  observerFee?:string,
  observerCurrency?:string,
  observerDiscount?:string,
  subscriberFee?: string;
  subscriberCurrency?: string;
  subscriberDiscount?: string;
  subscribeObserverFee?: string;
  subscribeObserverCurrency?: string;
  subscribeObserverDiscount?: string;
}

export interface ILiveSessionAvailabilityForm {
  availableTimes:IAvailableTime[]
  sessionMeta: {
    startDate: string;
    endDate: string;
    liveClassType:string,
  },
}

export interface ISessionDetailsForm {
  highlightMedia:IContentAvatar,
  relatedSkills:string[],
  sessionOutcomes:string[]
  categories: string[];
  prerequisites?: { id: string; title: string; }[];
}
export interface ILiveClassFrequencyForm {
  frequency:string,
  startDate:string,
  endDate:string,
  startAt:string,
  endAt:string,
  dayOfTheWeek:string,
  dayOfTheMonth:string,
}
export interface ICourseGoals {
  goals:IGoal[]
}
export interface ICourseModules {
  modules:IModule[]
  isSequentialModuleNavigationEnabled: boolean;
}

export interface ICourseOnlySessionMeta {
  status:string,
  moduleIndex:number
}
export interface CreateSessionState {
  stateLoading:boolean,
  loading:boolean,
  status:number,
  entId:string,
  expertId:string,
  sessionId:string,
  isActive:boolean,
  sessionInformation:ICreateSessionForm,
  sessionDetails: ISessionDetailsForm,
  sessionFee:ISessionFeeForm,
  liveAvailability:ILiveSessionAvailabilityForm,
  liveClassFrequency:ILiveClassFrequencyForm,
  courseModules:ICourseModules,
  courseGoals : ICourseGoals,
  filteredGroups:IFiltered<IGroupAvatar>,
  subStateLoading:boolean,
  invitations:IInvitation[],
  modalState:string,
  courseOnlySessionMeta : ICourseOnlySessionMeta;
  bookedSessions: number;
  bookedObservers: number;
}
export interface IPaginateFilterPayload {
  groupType:string,
  keyword: string;
  page: number;
  limit: number;
}

export interface ISessionCreatedResponse {
  session: SessionDocument;
  createNewsfeed: any;
  sessionModuleIndex?: string | null;
}
