/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextField, useTheme } from '@mui/material';

import './index.scss';
import moment from 'moment';
import { Label } from '../custom-typography';

interface PrimaryDatePickerProps {
  value: any;
  onChange:(value: any) => void;
  placeholder?:string;
  minDate?:any;
  maxDate?:any;
  label?:string;
  disabled?: boolean;
  disablePast?: boolean;
}

const PrimaryDatePicker = React.forwardRef(({
  onChange, value, placeholder, label, minDate, maxDate, disabled, disablePast,
}: PrimaryDatePickerProps, _ref) => {
  const theme = useTheme();

  const style = React.useMemo(() => ({
    outline: 'none',
    '& .MuiOutlinedInput-input': {
      padding: '8.5px 14px',
      borderRadius: '2px',
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    '&.Mui-focused .MuiOutlinedInput-input': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey.A400,
        borderRadius: '4px',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey.A400,
        borderWidth: '1px',
      },
    },
  }), [theme]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {label && <Label>{label}</Label>}
      <MobileDatePicker
        value={value}
        inputFormat="MM/dd/yyyy"
        onChange={onChange}
        minDate={minDate ?? minDate.toDate()}
        maxDate={maxDate ? maxDate.toDate() : moment(value).add(100, 'years').toDate()}
        disabled={disabled}
        disablePast={disablePast}
        renderInput={(params) => (
          <TextField
            sx={style}
            {...params}
            placeholder={placeholder}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  );
});

PrimaryDatePicker.defaultProps = {
  placeholder: '',
  label: '',
  minDate: false,
  maxDate: false,
  disabled: false,
  disablePast: true,
};
export default PrimaryDatePicker;
