/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import {
  Avatar, Box, CardHeader, Typography,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridRowId,
  GridColumns,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StatusTag, PrimaryTable, ConfirmationModal } from 'components';
import { ENVIRONMENT } from 'config';
import { IEntAccount, ModalStates } from '../../interface';
import { viewEntAccActions } from '../../slice';
import { selectLoader } from '../../selector';

interface EntAccountTableProps {
  entAccounts: IEntAccount[],
  loading: boolean,
  total: number,
  page: number
  setPage: (page: number) => void,
}
export default function EntAccountTable({
  entAccounts, loading, total, setPage, page,
}: EntAccountTableProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const confirmationLoading = useSelector(selectLoader);
  type Row = typeof entAccounts[number];

  const edit = React.useCallback(
    (id: GridRowId) => () => {
      navigate(`edit/${id}/general-information`);
    },
    [],
  );
  const deleteEnt = React.useCallback(
    (id: GridRowId) => () => {
      dispatch(
        viewEntAccActions.openModal({ id: id as string, state: ModalStates.delete }),
      );
    },
    [],
  );
  const activate = React.useCallback(
    (id: GridRowId) => () => {
      dispatch(
        viewEntAccActions.openModal({ id: id as string, state: ModalStates.active }),
      );
    },
    [],
  );

  const makeDefault = React.useCallback(
    (id: GridRowId) => () => {
      dispatch(
        viewEntAccActions.openModal({ id: id as string, state: ModalStates.favorite }),
      );
    },
    [],
  );

  const handleClose = (id:string) => { dispatch(viewEntAccActions.closeModal(id)); };
  const deleteAccount = (id:string, email:string) => {
    dispatch(viewEntAccActions.deleteEntAccount({ id, email }));
  };
  const editActiveAccount = (id:string, active:boolean) => {
    dispatch(viewEntAccActions.editActiveEntAccount({ id, active }));
  };

  const makeFavorite = (id:string, isDefault:boolean) => {
    dispatch(viewEntAccActions.editPinnedEnt({ id, isDefault }));
  };

  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        sortComparator: (v1, v2) => {
          const firstName1 = v1 || '';
          const firstName2 = v2 || '';
          return firstName1.localeCompare(firstName2);
        },
        renderCell: (params) => (
          <>
            <CardHeader
              onClick={edit(params.id)}
              avatar={(
                <Avatar
                  src={params.row.webLogoUrl === '' ? '/static/images/avatar/1.jpg' : params.row.webLogoUrl}
                  sx={{ width: 40, height: 40 }}
                />
              )}
              title={(
                <Typography variant="h4" noWrap>
                  {params.value}
                </Typography>
              )}
              subheader={`${params.row.entUrl}${ENVIRONMENT.USER_HUB_DOMAIN}`}
              subheaderTypographyProps={{ noWrap: true }}
            />

            <ConfirmationModal
              open={params.row.modal === ModalStates.delete}
              onClose={() => handleClose(params.id as string)}
              title="Delete account"
              onSubmit={() => deleteAccount(params.id as string, params.row.email)}
              loading={confirmationLoading}
              success={false}
              primaryButtonValue="Delete"
            >
              <Box sx={{ paddingBottom: ' 1.5rem', paddingTop: '1rem', width: '25rem' }}>
                <Typography variant="body1">
                  Are you sure you want to delete the enterprise client
                  {' '}
                  <span style={{ color: '#9D8336', fontWeight: 'bold' }}>{params.value}</span>
                  ?
                </Typography>
              </Box>
            </ConfirmationModal>
            <ConfirmationModal
              open={params.row.modal === ModalStates.active}
              onClose={() => handleClose(params.id as string)}
              title={!params.row.active ? 'Active account' : 'Deactivate account'}
              onSubmit={() => editActiveAccount(params.id as string, !params.row.active)}
              loading={confirmationLoading}
              success={!params.row.active}
              primaryButtonValue={!params.row.active ? ' Activate now' : 'Deactivate'}
            >
              <Box sx={{ paddingBottom: ' 1.5rem', paddingTop: '1rem', width: '25rem' }}>
                <Typography variant="body1">
                  Are you sure you want to
                  {' '}
                  {params.row.active ? 'deactivate' : 'activate'}
                  {' '}
                  the enterprise client
                  {' '}
                  <span style={{ color: '#9D8336', fontWeight: 'bold' }}>{params.value}</span>
                  ?
                </Typography>
              </Box>
            </ConfirmationModal>
            <ConfirmationModal
              open={params.row.modal === ModalStates.favorite}
              onClose={() => handleClose(params.id as string)}
              title={!params.row.isDefault ? 'Make account default favorite' : 'Remove account from default favorite'}
              onSubmit={() => makeFavorite(params.id as string, !params.row.isDefault)}
              loading={confirmationLoading}
              success={!params.row.isDefault}
              primaryButtonValue={!params.row.isDefault ? 'Make it default' : ' Remove'}
            >
              <Box sx={{ paddingBottom: ' 1.5rem', paddingTop: '1rem', width: '25rem' }}>
                <Typography variant="body1">
                  Are you sure you want to
                  {' '}
                  {!params.row.isDefault ? 'add' : 'remove'}
                  {' '}
                  the enterprise client
                  {' '}
                  <span style={{ color: '#9D8336', fontWeight: 'bold' }}>
                    {params.value}
                    {' '}
                  </span>
                  {!params.row.isDefault ? 'to' : 'from'}
                  {' '}
                  default favorite
                  ?
                </Typography>
              </Box>
            </ConfirmationModal>
          </>

        ),
        flex: 1,
      },
      {
        field: 'active',
        sortable: true,
        sortComparator: (v1, v2) => (v1 === v2 ? 0 : v1 ? -1 : 1),
        headerName: 'Status',
        renderCell: (params) => (
          <StatusTag success={params.value} status={params.value ? 'Active' : 'Inactive'} />

        ),
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
      },
      {
        field: 'email', headerName: 'Admins', flex: 0.5, headerAlign: 'right', align: 'right', valueGetter: (params) => params.row.userCounts?.admins, sortComparator: (v1, v2) => v1 - v2, renderCell: (params) => <div>{params.row?.userCounts.admins}</div>,
      },
      {
        field: 'userCounts', headerName: 'Experts', flex: 0.5, headerAlign: 'right', align: 'right', valueGetter: (params) => params.row.userCounts?.experts, sortComparator: (v1, v2) => v1 - v2, renderCell: (params) => <div>{params.row?.userCounts?.experts}</div>,
      },
      {
        field: 'createdAt',
        sortable: true,
        headerName: 'Date Added',
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => <div>{params.value.split('T')[0]}</div>,
      },
      {
        field: 'slocoachAdmin',
        sortable: true,
        headerName: 'SC Admin',
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <div>
            {' '}
            {params.value?.firstName}
            {' '}
            {params.value?.lastName}
          </div>
        ),
        sortComparator: (v1, v2) => v1.firstName.localeCompare(v2.firstName),
      },
      {
        field: 'actions',
        sortable: false,
        type: 'actions',
        flex: 0.5,
        align: 'right',
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            label="Edit"
            onClick={edit(id)}
            showInMenu
            sx={{ border: '1px solid #ddd', borderBottom: 0 }}
          />,
          <GridActionsCellItem
            label={row.active ? 'Deactivate' : 'Active'}
            onClick={activate(id as string)}
            showInMenu
            sx={{ border: '1px solid #ddd', borderBottom: 0 }}
          />,
          <GridActionsCellItem
            label="Delete"
            onClick={deleteEnt(id as string)}
            showInMenu
            sx={{ border: '1px solid #ddd', borderBottom: 0 }}
          />,
          <GridActionsCellItem
            label={row.isDefault ? 'Remove from default favorite' : 'Add to default favorite'}
            onClick={makeDefault(id as string)}
            showInMenu
            sx={{ border: '1px solid #ddd' }}
          />,
        ],
      },
    ],
    [edit, deleteEnt, makeDefault, activate, confirmationLoading],
  );
  return (
    <PrimaryTable
      rows={entAccounts}
      columns={columns}
      loading={loading}
      page={page}
      pageSize={25}
      rowsPerPageOptions={[25]}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      rowCount={total}
      paginationMode="server"
      getRowId={(row) => row._id}
    />
  );
}
