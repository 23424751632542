/* eslint-disable react/require-default-props */
import React from 'react';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { CustomOutlineInputField } from '../primary-text-field';

interface SearchBarProps {
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string;
  name?: string;
  placeholder?: string;
}
const SearchBar = React.forwardRef(
  ({
    onChange, onBlur, value, name, placeholder,
  }: SearchBarProps, ref) => (
    <CustomOutlineInputField
      onChange={onChange ?? undefined}
      startAdornment={(
        <InputAdornment position="start">
          <SearchIcon sx={{ color: '#E5E5E5' }} />
        </InputAdornment>
      )}
      type="text"
      size="small"
      onBlur={onBlur ?? undefined}
      value={value ?? undefined}
      name={name ?? undefined}
      ref={ref ?? undefined}
      placeholder={placeholder ?? undefined}
      fullWidth
    />
  ),
);
export default SearchBar;
