import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  clearAuthToken, getAuthToken, setAuthToken, setRefreshToken,
} from 'helpers/token';
import { IRestApiResponse } from 'interfaces';
import { request } from 'utils/request';
import { Role } from 'interfaces/user-roles';
import { coreSliceActions } from 'base/core/slice';
import { IRole } from 'interfaces/base-user';
import { ISignInForm } from 'pages/sign-in/interface';
import { IForgotPasswordForm } from 'pages/forgot-password/interface';
import { IResetPasswordForm } from 'pages/reset-password/interface';
import { IResetTempPasswordForm } from 'pages/reset-temp-password/interface';
import API from './constants';
import { authSliceActions } from './slice';
import { selectRole } from './selectors';

export function* signInGenerator({ payload }: PayloadAction<ISignInForm>) {
  try {
    const response: IRestApiResponse = yield call(request, API.POST_SIGN_IN, payload, false);
    if (response.statusCode === 201) {
      setAuthToken(response.data.idToken.jwtToken);
      setRefreshToken(response.data.refreshToken.token);
      yield put(coreSliceActions.getExchangeRates());
      yield put(authSliceActions.signInSucceeded({ idToken: response.data.idToken }));
    }
    yield put(authSliceActions.signInFailed('Authorization failed'));
  } catch (error) {
    yield put(authSliceActions.signInFailed('Authorization failed'));
  }
}
export function* accDetailsGenerator() {
  try {
    const token = getAuthToken();
    if (token) {
      const response: IRestApiResponse = yield call(
        request,
        API.GET_USER_DETAILS,
        null,
        true,
      );
      if (response.statusCode === 200) {
        const { roles } = response.data;
        if (roles.some((role:IRole) => role.role === Role.EntAccountAdmin
        || role.role === Role.SuperAdmin)) {
          yield put(authSliceActions.accDetailsSucceeded(response.data));
        } else {
          // login fails if the user is not a super admin or client admin
          yield put(authSliceActions.accDetailsFailed());
        }
      } else {
        yield put(authSliceActions.accDetailsFailed());
      }
    }
  } catch (error) {
    yield put(authSliceActions.accDetailsFailed());
  }
}
export function* entAccDetailsGenerator() {
  try {
    const token = getAuthToken();
    const role:string = yield select(selectRole);
    if (token) {
      if (role === Role.EntAccountAdmin) {
        const response: IRestApiResponse = yield call(
          request,
          API.GET_ENT_ACC,
          null,
          true,
        );
        if (response.statusCode === 200) {
          yield put(authSliceActions.entAccDetailsSucceeded(response.data));
        } else {
          yield put(authSliceActions.entAccDetailsFailed());
        }
      }
    }
  } catch (error) {
    yield put(authSliceActions.entAccDetailsFailed());
  }
}

export function* signOutGenerator() {
  try {
    clearAuthToken();
    localStorage.removeItem('persist:root'); // remove data from local storage
    localStorage.removeItem('showNotificationBar');
    sessionStorage.clear(); // remove data from session storage
    yield put(authSliceActions.signOutSucceeded());
  } catch (err) {
    yield put(authSliceActions.signOutFailed('User request failed'));
  }
}
export function* getCodeGenerator({ payload }: PayloadAction<IForgotPasswordForm>) {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_FORGOT_PASSWORD,
      { email: payload.name },
      false,
    );
    if (response.statusCode === 201) {
      window.location.pathname = '/login';
      yield put(authSliceActions.getCodeSucceeded({ code: response }));
    } else {
      yield put(authSliceActions.getCodeFailed('Authorization failed'));
    }
  } catch (error) {
    yield put(authSliceActions.getCodeFailed('Authorization failed'));
  }
}
export function* resetPasswordGenerator({ payload }:PayloadAction<IResetPasswordForm>) {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_RESET_PASSWORD,
      {
        email: payload.name,
        token: payload.verificationCode,
        newPassword: payload.confirmPassword,
      },
      false,
    );
    window.location.pathname = '/login';
    yield put(authSliceActions.resetPasswordSucceeded({ code: response }));
  } catch (error) {
    yield put(authSliceActions.resetPasswordFailed('Authorization failed'));
  }
}
export function* resetTempPasswordGenerator({ payload }: PayloadAction<IResetTempPasswordForm>) {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_RESET_TEMP_PASSWORD,
      payload,
      false,
    );
    if (response.statusCode === 201) {
      setAuthToken(response.data.idToken.jwtToken);
      setRefreshToken(response.data.refreshToken.token);
      yield put(
        authSliceActions.resetTempPasswordSucceeded({ idToken: response.data.idToken }),
      );
    }
    yield put(authSliceActions.resetTempPasswordFailed('Authorization failed'));
  } catch (error) {
    yield put(authSliceActions.resetTempPasswordFailed('Authorization failed'));
  }
}

export function* authSaga() {
  yield all([
    takeLatest(authSliceActions.signIn.type, signInGenerator),
    takeLatest(authSliceActions.signInSucceeded.type, accDetailsGenerator),
    takeLatest(authSliceActions.accDetailsSucceeded.type, entAccDetailsGenerator),
    takeLatest(authSliceActions.signOut.type, signOutGenerator),
    takeLatest(authSliceActions.resetPassword.type, resetPasswordGenerator),
    takeLatest(authSliceActions.resetTempPassword.type, resetTempPasswordGenerator),
    takeLatest(authSliceActions.resetTempPasswordSucceeded.type, accDetailsGenerator),
    takeLatest(authSliceActions.accDetailsSucceeded.type, entAccDetailsGenerator),
    takeLatest(authSliceActions.getCode.type, getCodeGenerator),
  ]);
}
export default authSaga;
