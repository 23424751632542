import { all, fork } from 'redux-saga/effects';
import { authSaga } from 'base/auth/saga';
import { createEntAccSaga } from 'pages/dashboard/components/ent-account/components/create-ent-account/saga';
import { viewEntAccSaga } from 'pages/dashboard/components/ent-account/components/ent-accounts-view/saga';
import { editEntAccSaga } from 'pages/dashboard/components/ent-account/components/edit-account/saga';
import { viewExpertSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/view-experts/saga';
import { createExpertSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/create-expert/saga';
import { createAdminSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/components/create-admin/saga';
import { editAdminSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/components/edit-admin/saga';
import { editExpertSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/saga';
import { viewAdminSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/components/view-admins/saga';
import { viewUserSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/users/components/view-users/saga';
import { viewGroupSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/components/view-groups/saga';
import { viewArticleSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/articles/components/view-articles/saga';
import { createArticleSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/articles/components/create-article/saga';
import { createSessionSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/sessions/components/create-session/sage';
import { viewSessionsSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/sessions/components/view-sessions/sage';
import { viewContentSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/content/saga';
import { viewSessionSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/sessions/components/view-session/saga';
import { newsFeedSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/news-feed/saga';
import { viewReviewsSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/reviews/saga';
import { viewFeedbackSaga } from 'pages/dashboard/components/feedback/saga';
import { editGroupSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/components/edit-groups/saga';
import { overviewSaga } from 'pages/dashboard/components/overview/saga';
import { createUserSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/users/components/create-user/saga';
import { createGroupSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/components/create-group/saga';
import { onboardEntAccSaga } from 'pages/dashboard/components/ent-account/components/onboard-ent-account/saga';
import { editUserSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/users/components/edit-user/saga';
import { viewFormsSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/components/forms/saga';
import { coreSaga } from 'base/core/saga';
import { viewGiftCardSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/gift-cards/components/view-gift-cards/saga';
import { createGiftCardSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/gift-cards/components/create-gift-card/saga';
import { viewGiftCardUsersSaga } from 'pages/dashboard/components/ent-account/components/edit-account/components/gift-cards/components/view-users/saga';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(coreSaga),
    fork(overviewSaga),
    fork(createEntAccSaga),
    fork(viewEntAccSaga),
    fork(onboardEntAccSaga),
    fork(editEntAccSaga),
    fork(viewExpertSaga),
    fork(createExpertSaga),
    fork(editExpertSaga),
    fork(viewSessionsSaga),
    fork(createSessionSaga),
    fork(viewSessionSaga),
    fork(viewContentSaga),
    fork(newsFeedSaga),
    fork(viewReviewsSaga),
    fork(viewFormsSaga),
    fork(createAdminSaga),
    fork(editAdminSaga),
    fork(viewAdminSaga),
    fork(viewUserSaga),
    fork(viewGroupSaga),
    fork(createGroupSaga),
    fork(editGroupSaga),
    fork(viewArticleSaga),
    fork(createArticleSaga),
    fork(createUserSaga),
    fork(editUserSaga),
    fork(viewFeedbackSaga),
    fork(viewGiftCardSaga),
    fork(createGiftCardSaga),
    fork(viewGiftCardUsersSaga),
  ]);
}
